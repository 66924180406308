import accessibleAutocomplete from 'accessible-autocomplete';

interface AutoSuggestResponse {
    suggestions: Array<Record<string, string>>,
    errorMessage: string
}

let searchResultTypes: Record<string, Record<string, string | number>> = {};

// 'this' is to be bound before using
function fetchSuggestions (this: {endpoint: string, additionalQuery: string}, query: string, populateResults: (s: string[]) => void): void {
    fetch(this.endpoint + '?tx_adeg_merchantsuggest[queryString]=' + query + this.additionalQuery, {
        method: 'GET'
    })
        .then((response): Promise<AutoSuggestResponse> => {
            searchResultTypes = {};
            if (response.status === 200) {
                return response.json();
            } else {
                throw new Error(response.status.toString() + ' ' + response.statusText);
            }
        })
        .then((response: AutoSuggestResponse) => {
            if (response instanceof Object) {
                if (response.errorMessage === '') {
                    if (response.suggestions) {
                        const suggestionArray: string[] = [];
                        response.suggestions.forEach((suggestion: Record<string, string>) => {
                            suggestionArray.push(suggestion.title);
                            searchResultTypes[suggestion.title] = {
                                type: suggestion.type,
                                geoLocation: suggestion.geoLocation,
                                numFound: parseInt(suggestion.numFound),
                                url: suggestion.url ? suggestion.url : '',
                                title: suggestion.title ? suggestion.title : ''
                            };
                        });
                        populateResults(suggestionArray);
                    }
                } else {
                    throw new Error(response.errorMessage);
                }
            }
            return null;
        })
        .catch((error) => {
            console.error(error);
        });
}

function autocomplete (containerElement: HTMLElement, onConfirm: (name: string, result: Record<string, string | number>) => void): void {
    const inputElement = document.getElementById(containerElement.dataset.autocompleteFor || '') as HTMLInputElement|null;
    if (!inputElement || !inputElement.form) {
        return;
    }

    const autocompleteType = inputElement.form.dataset.type || '';
    const autocompleteParameters = {
        endpoint: inputElement.form.dataset.suggest || '',
        additionalQuery: autocompleteType ? '&tx_adeg_merchantsuggest[type]=' + autocompleteType : ''
    };

    // Get previous query term and input name from document
    const options: { [key: string]: unknown } = {
        element: containerElement,
        id: 'merchant-search-term',
        defaultValue: inputElement.value,
        name: inputElement.name,
        showNoOptionsFound: false,
        source: fetchSuggestions.bind(autocompleteParameters),
        minLength: 2,
        placeholder: window.localizationLabels.placeholder,
        autoselect: true,
        onConfirm: (selection: string) => {
            const selectedDocument = document.createElement('div');
            selectedDocument.innerHTML = selection.trim();
            const selectedDocumentName = selectedDocument.textContent || selectedDocument.innerText || '';
            if (selectedDocumentName) {
                if (selectedDocumentName in searchResultTypes) {
                    onConfirm(selectedDocumentName, searchResultTypes[selectedDocumentName]);
                }
            }
        },
        confirmOnBlur: false,
        templates: {
            inputValue: (selected: string): string | void => {
                return selected;
            },
            suggestion: (suggestion: string): string => {
                if (suggestion in searchResultTypes) {
                    let iconLink = '/typo3conf/ext/adeg/Resources/Public/Images/Icons/';
                    let srText = '';
                    if (searchResultTypes[suggestion].type === 'tx_adeg_merchants') {
                        iconLink = iconLink + 'pin-green.svg';
                        srText = window.localizationLabels.merchantSrText;
                    } else {
                        iconLink = iconLink + 'pin.svg';
                        srText = window.localizationLabels.locationSrText;
                    }
                    suggestion = '<img alt="" src="' + iconLink + '" /><span>' + searchResultTypes[suggestion].title.toString() + '</span><span class="sr-only">, ' + srText + '</span>';
                }
                return suggestion;
            }
        },
        tAssistiveHint: () => '', // too annoying: 'Nutze die Pfeiltasten und die Eingabetaste zur Auswahl. Am Touch-Screen verwende Berührungen und Wischgesten.'
        tStatusNoResults: () => '', // too annoying: 'Keine Suchvorschläge'
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        tStatusSelectedOption: (selectedOption: string, length: number, index: number) => '' // too annoying: `${selectedOption} ${index + 1} von ${length} ist hervorgehoben.`
    };

    // Remove original input field before autocomplete init, as that adds its own
    inputElement.remove();

    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    accessibleAutocomplete(options);
}

export default autocomplete;
