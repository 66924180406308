const defaultOptions: PositionOptions = {
    enableHighAccuracy: false,
    timeout: 5000, // milliseconds
    maximumAge: 5 * 60 * 1000 // milliseconds
};

function getCookie (name: string): string|null {
    const nameLengthPlusOne = (name.length + 1); // for the = after the name
    return document.cookie
        .split(';')
        .map((cookie) => cookie.trim())
        .filter((cookie) => cookie.startsWith(`${name}=`))
        .map((cookie) => decodeURIComponent(cookie.substring(nameLengthPlusOne)))[0] || null;
}

function getCachedPosition (): GeolocationPosition | null {
    const position = getCookie('geolocation');
    if (position) {
        const [latitude, longitude, timestamp] = position.split('-');
        return {
            coords: {
                latitude: parseFloat(latitude), // in degrees
                longitude: parseFloat(longitude), // in degrees
                accuracy: 0.0, // in meters
                altitude: null,
                altitudeAccuracy: null,
                heading: null, // in degrees (0° is north)
                speed: null // in meters per second
            },
            timestamp: parseFloat(timestamp) // "[...] milliseconds, either as an absolute time (relative to some epoch) or as a relative amount of time."
        };
    }
    return null;
}

function getCurrentPosition (options?: PositionOptions): Promise<GeolocationPosition> {
    if (!window.currentPositionPromise) {
        window.currentPositionPromise = new Promise(
            (resolve, reject) => {
                navigator.geolocation.getCurrentPosition(resolve, reject, options);
            }
        );
    }
    return window.currentPositionPromise;
}

function getGeolocationPosition (options?: PositionOptions): Promise<GeolocationPosition> {
    if (navigator.geolocation) {
        const cachedPosition = getCachedPosition();
        if (cachedPosition === null) {
            return getCurrentPosition(options || defaultOptions);
        } else {
            return new Promise(
                (resolve) => {
                    resolve(cachedPosition);
                }
            );
        }
    } else {
        throw Error('No support for geolocation API.');
    }
}

export default getGeolocationPosition;
