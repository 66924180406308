import getGeolocationPosition from './modules/geolocation';
import autocomplete from './modules/merchantAutocomplete';

const wrapper: HTMLElement | null = document.querySelector('#merchant-selector-wrapper');
const body: HTMLElement | null = document.querySelector('body');
const resultContainer: HTMLElement | null = document.querySelector('#merchant-selector-results');
const detailContainer: HTMLElement | null = document.querySelector('#merchant-selector-detail');
const searchForm: HTMLFormElement | null = document.querySelector<HTMLFormElement>('#merchant-selector-form');

let searchCoordinates: string | undefined;

declare global {
    /* eslint-disable @typescript-eslint/naming-convention */
    interface Window { OnetrustActiveGroups: string; }
}

function init (): void {
    if (!wrapper || !body) {
        return;
    }

    window.addEventListener('click', (event) => {
        const clickTarget = event.target as HTMLElement;

        if (clickTarget.closest('#merchant-selector, .merchant-selector-toggle') === null) {
            wrapper.classList.add('d-none');
            body.classList.remove('overflow-hidden');
        }
    });

    const searchToggle = document.querySelectorAll('.merchant-selector-toggle');
    searchToggle.forEach((item) => {
        item.addEventListener('click', (event) => {
            event.preventDefault();
            wrapper.classList.toggle('d-none');
            body.classList.toggle('overflow-hidden');
        });
    });

    const searchForm: HTMLFormElement | null = document.querySelector('#merchant-selector-form');
    if (searchForm) {
        searchForm.addEventListener('submit', (event) => {
            event.preventDefault();
        });
    }

    const autocompleteContainer = document.getElementById('merchant-selector-autocomplete');
    const autocompleteInput = document.getElementById('merchant-selector-input');
    if (autocompleteContainer && autocompleteInput) {
        const autocompleteConfirmationOptions = {
            inputElement: <HTMLInputElement>autocompleteInput,
            openUrl: true
        };
        if (autocompleteContainer.dataset.doNotLinkMerchant) {
            autocompleteConfirmationOptions.openUrl = false;
        }
        autocomplete(autocompleteContainer, handleAutocompleteConfirmation.bind(autocompleteConfirmationOptions));
    }
}

function handleAutocompleteConfirmation (this: {inputElement: HTMLInputElement, openUrl: boolean}, name: string, result: Record<string, string | number>): void {
    // Event triggers before inputElement has the value, but rest of the code needs it, so set explicitly
    const merchantInputField = <HTMLInputElement>document.getElementById('merchant-selector-input');
    if (merchantInputField) {
        merchantInputField.value = name;
    }

    const merchantInputHiddenField = <HTMLInputElement>document.getElementById('merchant-selector-input-hidden');
    if (merchantInputHiddenField) {
        merchantInputHiddenField.value = '';
    }

    // NOTE: setting or removing the search center triggers a new a search via XHR
    if (result.type === 'osm_location') {
        searchCoordinates = result.geoLocation.toString();
        prepareFormData();
    } else {
        if (merchantInputHiddenField) {
            merchantInputHiddenField.value = name;
        }

        searchCoordinates = undefined;
        prepareFormData();
    }
}

function prepareFormData (): void {
    if (searchForm instanceof HTMLFormElement) {
        const formData = new FormData(searchForm);
        if (formData.has('distance')) {
            if (searchCoordinates !== undefined) {
                formData.append('coordinates', searchCoordinates);
                submitForm(formData);
            } else {
                getGeolocationPosition()
                    // eslint-disable-next-line promise/always-return
                    .then((position) => {
                        searchCoordinates = `${position.coords.latitude},${position.coords.longitude}`;
                        formData.append('coordinates', searchCoordinates);
                        submitForm(formData);
                    })
                    .catch((error) => {
                        submitForm(formData);
                        console.error(error);
                    });
            }
        } else {
            submitForm(formData);
        }
    }
}

function submitForm (data: FormData): void {
    if (data.get('tx_solr[q]') === '') {
        data.delete('tx_solr[q]');
    }

    fetch('/?type=7385', {
        method: 'POST',
        body: data
    }).then((response) => {
        return response.json();
    })
        .then((resultJson) => {
            if (resultContainer && resultJson.htmlList) {
                resultContainer.innerHTML = resultJson.htmlList;

                resultContainer.querySelectorAll('a.merchant-selector-detail-link').forEach((link) => {
                    link.addEventListener('click', handleDetailLink);
                });
            }
        })
        .catch((error) => {
            console.error(error);
        });
}
function handleDetailLink (event: Event): void {
    event.preventDefault();

    const link = event.target as HTMLAnchorElement;

    fetch(link.href, {
        method: 'GET'
    }).then((response) => {
        return response.text();
    })
        .then((resultHtml) => {
            if (detailContainer) {
                detailContainer.innerHTML = resultHtml;
                detailContainer.classList.remove('d-none');
                document.getElementById('merchant-selector')?.classList.remove('overflow-y-scroll');

                detailContainer.querySelector('#merchant-selector-back')?.addEventListener('click', (event) => {
                    event.preventDefault();
                    detailContainer.classList.add('d-none');
                    document.getElementById('merchant-selector')?.classList.add('overflow-y-scroll');
                });

                if (window.OnetrustActiveGroups && window.OnetrustActiveGroups.split(',').filter((value: string) => value.trim()).includes('C0002')) {
                    const googleImages = detailContainer.querySelectorAll('img.merchant-location');

                    googleImages.forEach((googleImage) => {
                        const imgSrc = googleImage.getAttribute('data-src');
                        if (imgSrc) {
                            googleImage.setAttribute('src', imgSrc);
                            googleImage.classList.remove('d-none');
                        }
                    });
                }
            }
        })
        .catch((error) => {
            console.error(error);
        });
}

if (document.readyState === 'loading') {
    document.addEventListener('DOMContentLoaded', () => init());
} else {
    init();
}

export {};
